@import "../assets/fonts/font.css";
:root {
  --ion-color-primary: #00D082;
  --ion-color-primary-rgb: 0,208,130;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #00b772;
  --ion-color-primary-tint: #1ad58f;

  --ion-color-secondary: #5048F2;
  --ion-color-secondary-rgb: 80,72,242;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #463fd5;
  --ion-color-secondary-tint: #625af3;

  --ion-color-tertiary: #1C1C1C;
  --ion-color-tertiary-rgb: 28,28,28;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #191919;
  --ion-color-tertiary-tint: #333333;
  --ion-color-tertiary-light: #C5C5C7;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #DA291C;
  --ion-color-danger-rgb: 218,41,28;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #c02419;
  --ion-color-danger-tint: #de3e33;

  --ion-color-warn:#deb4b4;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #000000;
  --ion-color-medium-rgb: 0,0,0;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #000000;
  --ion-color-medium-tint: #1a1a1a;

  --ion-color-light: #FAFAFB;
  --ion-color-light-rgb: 250,250,251;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #dcdcdd;
  --ion-color-light-tint: #fbfbfb;
  --ion-color-grey-shade:#eceaea;
  --ion-color-dark-grey-shade:#dcdcdc;


  --ion-input-background-color: #F5F5F5;
  --ion-control-border: #DADADA;

  --ion-font-family: 'Inter', 'sans-serif';

  --ion-font-weight-thin: 300;
  --ion-font-weight-normal: 400;
  --ion-font-weight-mild: 600;
  --ion-font-weight-semibold: 700;
  --ion-font-weight-bold: 800;
  --ion-font-weight-bolder: 900;

  --ion-color-white: #ffffff;
  --ion-color-black: #000000;
}

// iOS PWA Fix
html.plt-ios.plt-pwa {
  --ion-safe-area-bottom : 5px !important;
  body {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    ion-app {
      margin-bottom: 5px;
    }
  }
}
