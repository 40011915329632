.jatak-order-list {
  ion-accordion {
    position: relative;
    margin-bottom: 10px;
    ion-item[slot="header"] {
      width: 100%;
      border-radius: 8px;
      font-size: 1rem;
      font-weight: var(--ion-font-weight-semibold);
      background: var(--ion-color-white);
      border: 1px solid var(--ion-control-border);
      border-bottom: unset;
      --inner-padding-start: 0;
      --inner-padding-end:0;
      --background-hover: none;
      transition: border, border-radius 15ms ease;
      &::part(native) {
        --ripple-color: transparent;
        min-height: 44px;

      }
      ion-label{
        font-size: 1rem;
        span[sn] {
          margin-right: 30px;
        }
        span[alert] {
          color: var(--ion-color-danger);
          font-weight: var(--ion-font-weight-normal);
          ion-icon{
            font-size: 1.4rem;
            font-weight: var(--ion-font-weight-bolder);
            position: relative;
            top: 5px;
          }
          @media screen and (max-width: 480px) {
            span[alertMessage] {
              display: none;
            }
          }
        }
        span[picked], span[cancelled] {
          ion-icon{
            font-size: 1.3rem;
            font-weight: var(--ion-font-weight-bolder);
            position: relative;
            top: 5px;
          }
        }
        span[picked] {
          color: var(--ion-color-primary);
        }

        span[cancelled] {
          color: var(--ion-color-danger);
        }
      }

      ion-icon[slot="end"] {
        padding: 10px 5px;
        //font-size: 2rem;
        font-size: 24px;
        color: var(--ion-color-black);
        //border: 1px solid var(--ion-control-border);
        border-radius: 8px;
        margin: 0  ;
      }
    }

    div[slot="content"] {
      font-size: 0.65rem;
      color: var(--ion-color-dark);
      border: 1px solid var(--ion-control-border);
      border-top: unset;
      padding: 10px;
      position: relative;
      word-break: break-word;
      border-radius: 0 0 8px 8px;
    }
  }
  ion-accordion.accordion-expanded {
    ion-item[slot="header"] {
      border-radius: 8px 8px 0 0;
      border-bottom: unset;
    }
  }

  ion-accordion.accordion-expanding {
    ion-item {
      border-radius: 8px 8px 0 0;
      border-left: 1px solid var(--ion-control-border);
      border-right: 1px solid var(--ion-control-border);
    }
  }
}
