:root {
  ion-input,
  ion-textarea {
    --background: var(--ion-input-background-color);
    --color: var(--ion-color-tertiary);
    --padding-top: 11px;
    --padding-bottom: 11px;
    --padding-start: 18px;
    --placeholder-opacity: 0.4;
    font-size: 1rem;
    border-radius: 8px;
    margin-bottom: 10px;
    caret-color: var(--ion-color-primary);
  }

  ion-input:focus,
  ion-textarea:focus {
    --border-color: var(--ion-color-primary);
  }

  ion-toolbar {
    --background: white;
  }

  ion-item {
    --background-hover: none;
    --highlight-background-color: transparent !important;
  }


  ion-card {
    background: #F5F4F4;
    box-shadow: none;
    border-radius: 8px;
    margin: 0;
  }

  ion-card img {
    border-radius: 15px !important;
  }

  ion-header {
    box-shadow: none;
  }

  ion-item {
    ion-label.md {
      font-size: 1.3rem;
    }

    ion-label.md.ng-pristine.ng-invalid.sc-ion-input-md-h.sc-ion-input-md-s.md.hydrated.ng-touched.ion-pristine.ion-invalid.ion-touched {
      color: red;
    }

    ion-label.ios.label-stacked {
      font-size: 1rem;
      margin-top: 0 !important;
    }

    ion-label {
      text-overflow: ellipsis;
      overflow: visible;
      margin-bottom: 15px !important;
      margin-top: -10px !important;
      font-weight: 500;

      span {
        //font-size: 0.875rem;
      }

      &.sp-stacked {
        margin-bottom: 7px !important;
      }
    }

    ion-input,
    ion-textarea {
      --padding-top: 11px !important;
      --padding-bottom: 11px !important;
      --padding-start: 18px !important;
    }

    .link-text-area {
      ion-textarea {
        border: none !important;
      }
    }

    .link-text-area:focus-within {
      border: 1px solid var(--ion-color-primary);
    }
  }

  ion-item.item-has-focus > ion-input,
  ion-item.item-has-focus > ion-textarea {
    border: 1px solid var(--ion-color-primary);
  }

  ion-input.ng-pristine.ng-invalid.sc-ion-input-md-h.sc-ion-input-md-s.md.hydrated.ng-touched.ion-pristine.ion-invalid.ion-touched {
    border: 1px solid red;
  }


}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable, .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  height: 300px;
  padding: 15px 20px;
}

.ck-rounded-corners .ck.ck-toolbar, .ck.ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.ck-editor__editable_inline {
  p {
    margin: 0;
  }

  a {
    font-weight: 700;
  }
}

app-text-editor {
  width: 100%;
}

.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul,
.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

div.editor-content {
  p {
    margin-bottom: 0;

    a {
      font-weight: 700;
    }
  }

  ol,
  ul {
    padding-left: 40px;
  }
}

.ck-body-wrapper {
  display: none;
}

.ck-editor__editable_inline {
  height: 150px;
  background-color: #F5F5F5 !important;
}

.form-group {
  position: relative;
  margin-bottom: 14px;

  label {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    letter-spacing: -0.02px;
    margin-bottom: 8px;
    color: #1c1c1c;
    display: block;

  }

  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    --min-height: auto;
  }


  &:last-child {
    margin-bottom: 0px;
  }

  span.error-message {
    display: block;
    text-align: center;
    color: var(--ion-color-danger);
    margin: 5px 0 10px;
    width: 100%;
    font-size: 0.75rem;
  }

  &.error {
    ion-input,
    ion-textarea {
      border: 1px solid var(--ion-color-danger);

      &.has-focus {
        border: 1px solid var(--ion-color-danger);
      }
    }
  }
}

.form-group-sub-text {
  margin-bottom: 16px;
  //padding: 2px;

  .left-adjustment {
    margin: 8px 0 0 20px;
  }

  .form-sub-text {
    font-size: 0.75rem;
    padding: 2px;
    color: rgba(60, 60, 67, 0.6);
  }
}

.form-margin-fix {
  margin: 0 17px;
}

.form-padding-fix {
  padding: 0 17px;
}

.border-ctn {
  border-top: 1px solid var(--ion-control-border);
  border-bottom: 1px solid var(--ion-control-border);
}

.buttons-ctn {
  //padding: 0 16px;
}

@media screen and (max-width: 320px) {
  :root {
    ion-item {
      ion-label.ios.label-stacked {
        span {
          font-size: 0.75rem;
        }
      }
    }
  }
}

span.error-message {
  display: block;
  text-align: center;
  color: var(--ion-color-danger);
  margin: 5px 0 10px;
  width: 100%;
  font-size: 0.75rem;
}


.section-wrapper {
  .title {
    font-size: 18px;
    line-height: 21.78px;
    letter-spacing: -0.02px;
    color: #00D082;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .description {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.4px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .sub-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.4px;
    margin-bottom: 12px;
  }

  .date-time-description {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.12px;
    color: #3C3C4399;
    margin-bottom: 20px;
  }

}


input[type='text'],
input[type='email'],
input[type='password'],
input[type='time'],
input[type='date'],
input[type='number'],
input[type='tel'],
textarea {
  font-size: 16px;
  appearance: none;
  position: relative;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #C5C5C7;
  border-radius: 8px;
  margin-bottom: 0;
  background: #f5f5f5;
  outline: none;

}
