.pintura-editor{
  --color-background: 0,0,0!important;
  --color-foreground: 255, 255, 255!important;
  --color-primary: var(--ion-color-primary)!important;
  --color-primary-dark:var(--ion-color-primary)!important;
  --color-primary-text: #fff!important;
  --color-secondary: var(--ion-color-secondary);
  --color-secondary-dark: var(--ion-color-secondary);
  padding-bottom: 0!important;
  .PinturaTabPanels .PinturaTabPanel .PinturaPanel .PinturaUtilFooter .PinturaShapePresetSelect
  {
    display: none!important;
  }
  @media screen and (min-width: 768px) {
    --font-size: 20px;
  }
}

.PinturaUtilFooter .PinturaShapePresetsGrouped {
  width: 100%;
}

.PinturaRoot[data-env~=portrait][data-env~=is-compact]>.PinturaNavMain button {
  font-size: 0.9375rem!important;
  width: 44px!important;
  height: 44px!important;
}

.PinturaShapePresetsList .PinturaShapePreset {
  width: 50px!important;
}
.PinturaUtilFooter .PinturaShapePresetsFlat {
  width: 100%;
}
.PinturaUtilFooter .PinturaShapePresetSelect {
  display: none;
}
.PinturaNav button.PinturaButtonExport {
  height: 44px!important;
  width: 44px!important;
  svg.PinturaButtonIcon {
    width: 2.2rem;
  }
}
