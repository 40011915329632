.language-popover {
  --width: 35px;
}
.create-options-popover {
  --width: 80%;
  --max-width: 320px;
  &::part(content) {
    top: unset!important;
    //left: 0!important;
    bottom: 0;
    width: 100%;
    max-width: 600px;
  }
}
