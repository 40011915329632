:root {
  ion-button {
    --border-radius: 20px;
    --border-width: 2px;
    font-weight: var(--ion-font-weight-semibold);
    margin: auto;
    text-transform: none;
    --padding-top: 10px;
    --padding-bottom : 10px;
    height: 41px;
    font-size: 16px;
    line-height: 19.36px;
    letter-spacing: -0.4px;
  }
}
