/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* custom scss imports */
@import "src/theme/form";
@import "src/theme/button";
@import "src/theme/helper";
@import "src/theme/alert";
@import "src/theme/popover";
@import "src/theme/mobiscroll";
@import "src/theme/modal";
@import "src/theme/doka-image-editor";
@import "src/theme/accordion";


body {
  position: relative;
  font-family: 'Inter', 'sans-serif';
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  background: var(--ion-color-light);
  color: var(--ion-color-dark);
  overflow: hidden;
  color: var(--ion-color-dark);
}

ion-title.ios {
  font-size: 1.125rem;
  font-weight: var(--ion-font-weight-semibold);
}

ion-title.md {
  position: relative;
  left: -24px;
  font-size: 1.125rem;
  text-align: center;
  font-weight: var(--ion-font-weight-semibold);
}

.mbsc-ios.mbsc-form-control-wrapper:before, .mbsc-ios.mbsc-form-control-wrapper:after {
  border-top: none !important;
}

.content-max-width {
  max-width: 755px;
  margin: auto;
  padding: 0 20px;
  position: relative;
}

.tab-sub-label {
  font-size: 12px;
  font-weight: 400;
}

.tab-sub-label-bold {
  font-size: 12px;
  font-weight: 700;
}

.tab-options {
  display: flex;
  max-width: 755px;
  margin: auto;
}

.tab-options-wrapper {
  display: flex;
  justify-content: space-between;
  //margin: 0 18px;
}

.tab-options-title {
  width: 200px;
  margin-left: 0;
}

//.swiper-slide-active {
//  width: 100% !important;
//}

ion-app {
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
  overflow: hidden;
}

@media print {
  ion-tab-bar {
    display: none;
    border: 0;
  }
}

.col-wrapper {
  background: #fff;
}

.jatak-text-area {
  .textarea-wrapper.sc-ion-textarea-md {
    textarea.native-textarea.sc-ion-textarea-md {
      resize: vertical;
    }
  }
}

ion-segment-button {
  width: 33.333%;
}


$mbsc-ios-accent: #00D082;

@import "@mobiscroll/angular/dist/css/mobiscroll.scss";
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@media screen and (min-width: 1024px) {
  .md .toast-custom-class {
    transform: translateY(-56px) !important;
    --border-radius: 8px;
  }
  .ios .toast-custom-class {
    transform: translateY(-50px) !important;
    --border-radius: 8px;
  }
  .md .header-top {
    top: 56px !important;
  }
  .ios .header-top {
    top: 44px !important;
  }
}

.fab-vertical-bottom {
  //bottom: 10%;
  right: 27%;
}


.ionfab-right {
  margin-right: 13px !important;
}

.ionfab-left {
  margin-left: 13px !important;
}

.overlay .ionfab-left {
  z-index: -1;
}

.edit-signature {
  margin-right: 250px;
  align-items: end;
}

:host ::ng-deep .mbsc-ios.mbsc-datepicker-inline {
  margin: 10px 20px !important;
  border: 1px solid #BDBDBD !important;
  border-radius: 8px !important;
}

:host ::ng-deep .mbsc-ios.mbsc-calendar-button.mbsc-button {
  color: #000000 !important;
}

:host ::ng-deep .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
  background-color: #00D082 !important;
  border-color: #00D082 !important;
}

:host ::ng-deep .mbsc-ios.mbsc-range-day::after {
  background: #b2f2da !important;
}

.app-version {
  color: var(--ion-color-dark);
  font-size: 0.75rem;
  opacity: 0.5;
}

.get-help-btn {
  background-color: #FFFFFF;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .3), 0px 1px 3px 1px rgba(0, 0, 0, .15);
  color: #5048F2;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 26%;
  bottom: calc(10% - 56px);
  cursor: pointer;
  z-index: 9;

  ion-icon {
    font-size: 24px;
  }

}

.create-new {
  background-color: #000000;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .3), 0px 1px 3px 1px rgba(0, 0, 0, .15);
  color: #FFFFFF;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 26%;
  bottom: calc(10% - 56px);
  cursor: pointer;
  z-index: 9;

  ion-icon {
    font-size: 24px;
    color: #FFFFFF;
  }
}

// for mobile devices

@media only screen and (max-width: 767px) {
  .get-help-btn {
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .3), 0px 1px 3px 1px rgba(0, 0, 0, .15);
    color: #5048F2;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 15px;
    cursor: pointer;
    z-index: 9;

    ion-icon {
      font-size: 24px;
    }

  }

  .create-new {
    background-color: #000000;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .3), 0px 1px 3px 1px rgba(0, 0, 0, .15);
    color: #FFFFFF;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 15px;
    cursor: pointer;
    z-index: 9;

    ion-icon {
      font-size: 24px;
      color: #FFFFFF;
    }
  }

  #payment-div iframe {
    width: 400px !important;
    height: 400px !important;
    overflow: scroll !important;
    /* Add padding if you want some space around the iframe */
  }

}

// for tablet devices

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait),
only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) {
  .get-help-btn {
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .3), 0px 1px 3px 1px rgba(0, 0, 0, .15);
    color: #5048F2;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 4%;
    cursor: pointer;
    z-index: 9;

    ion-icon {
      font-size: 24px;
    }

  }

  .create-new {
    background-color: #000000;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .3), 0px 1px 3px 1px rgba(0, 0, 0, .15);
    color: #FFFFFF;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 4%;
    cursor: pointer;
    z-index: 9;

    ion-icon {
      font-size: 24px;
      color: #FFFFFF;
    }
  }
}


// for laptop devices

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .get-help-btn {
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .3), 0px 1px 3px 1px rgba(0, 0, 0, .15);
    color: #5048F2;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 15%;
    cursor: pointer;
    z-index: 9;

    ion-icon {
      font-size: 24px;
    }

  }

  .create-new {
    background-color: #000000;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .3), 0px 1px 3px 1px rgba(0, 0, 0, .15);
    color: #FFFFFF;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 15%;
    cursor: pointer;
    z-index: 9;

    ion-icon {
      font-size: 24px;
      color: #FFFFFF;
    }
  }
}


.form-group {
  .link-text-area {
    position: relative;
    border-radius: 8px;
    background: var(--ion-input-background-color);
    margin-bottom: 12px;
    padding-bottom: 10px;
    border: 1px solid #C5C5C7;

    .offertext {
      padding-bottom: 20px;
      border: 1px solid #C5C5C7;
    }

    .link-text {
      margin: 0 15px;
      padding: 15px 0;
      border-top: 1px dashed #000000;

      ion-text {
        white-space: pre-line;
        width: 95%;
        display: block;
        color: #666666;
      }
    }
  }
}

.buttons-ctn {

  margin: 24px 0;

  ion-button,
  .mb-15 {
    margin-bottom: 16px;
  }

  .disabled-btn {
    pointer-events: none;
    opacity: 0.4;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.btn {
  padding: 10px 20px;
  border-radius: 90px;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: -0.02px;
  font-weight: 600;
  cursor: pointer;
}

.btn-black-filled {
  color: #FFFFFF;
  background-color: #1C1C1C;
  border: 2px solid #1C1C1C;
  text-align: center;
}

.btn-white-filled {
  color: #1C1C1C;
  background-color: #FFFFFF;
  border: 2px solid #1C1C1C;
  text-align: center;
}

.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-justify {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}


.tab-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .options {
    font-size: 12px;
    line-height: 16.74px;
    letter-spacing: 0.12px;
    color: #666666;
    flex-basis: 30%;
    text-align: center;
    padding: 13px 10px;
    border-bottom: 2px solid #C5C5C7;
    cursor: pointer;
  }

  .activetab {
    font-weight: 700;
    color: #00D082;
    border-bottom: 2px solid #00D082;
  }
}


.list-container {
  margin-top: 20px;

  .list {
    padding: 10px 17px;
    border: 1px solid #C5C5C7;
    border-radius: 8px;
    margin-bottom: 15px;

    ion-icon {
      font-size: 17px;
    }

    .title-section {
      padding-left: 17px;
    }

    .date {
      font-size: 12px;
      line-height: 16.74px;
      letter-spacing: 0.12px;
    }

    .title {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.4pxpx;
      overflow: hidden;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}

.flex-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.no-list {
  text-align: center;
  margin-top: 30px;
  font-weight: 700;
}

.date-container {
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #F5F5F5;
  font-size: 12px;
  line-height: 16.74px;
  letter-spacing: 0.12px;

  ion-icon {
    font-size: 12px !important;
    margin-left: 5px;
  }
}

.dateseperater {
  height: 1px !important;
  background: #C5C5C7 !important;
  display: block !important;
  font-size: 2em !important;
  opacity: 1 !important;
  visibility: visible !important;
  //border-top: 1px solid #c5c5c7
  margin: 5px 0;
}

.hr {
  height: 1px !important;
  background: #C5C5C7 !important;
  margin: 32px 0;
}

.resetrange {
  padding: 8px;
  border: 2px solid #1C1C1C;
  cursor: pointer;
  border-radius: 8px;
  //max-width: 150px;
  position: absolute;
  top: 0;
  right: 0;
}


.itemlist {
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #C5C5C7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.itemsection {
  padding: 32px 0;
  cursor: pointer;
  border-bottom: 1px solid #C5C5C7;

  p {
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
  }


  .radio-btn-icon {
    font-size: 18px;
  }

  ion-icon {
    font-size: 12px;
  }

  .listsection {
    border: 1px solid #1C1C1C;
    padding: 0 20px;
    border-radius: 8px;
    margin-top: 12px;


    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 17px 0;
      border-bottom: 1px solid #C5C5C7;

      :last-child {
        border-bottom: none;
      }
    }
  }


}

.jatak-empty {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 15px;
  white-space: pre-line;

  & ion-text {
    padding-top: 20px;
    font-size: 16px;
    line-height: 19.36px;
    letter-spacing: -0.2px;
    opacity: 0.5;
  }
}

.cursor-pointer {
  cursor: pointer;
}


//ion-popover {
//  --background: #ffffff;
//  --backdrop-opacity: 0;
//  //--box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
//  --color: white;
//  --width: 300px;
//  --border-radius: 8px; /* Adds border radius */
//}
//
//ion-popover ion-content {
//  --background: #fff;
//  cursor: pointer;
//}
//
//ion-popover::part(backdrop) {
//  background-color: rgba(0, 0, 0, 0.7) !important; /* Fully transparent background */
//}


.description a {
  color: #1c1c1c;
}

.payment-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: #0009;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

#payment-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#payment-div iframe {
  width: 700px;
  height: 400px;
  overflow: scroll;
  /* Add padding if you want some space around the iframe */
}

.noborder {
  border-bottom: none !important;
}

.app-sub-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 16px 0;
  border-bottom: 1px solid #C5C5C7;
  background: #fbfbfb;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  letter-spacing: -0.02px;
  border-radius: 0 0 8px 8px;


  .back {
    position: absolute;
    left: 10px;
    top: 20px;
    cursor: pointer;
  }

  .back ion-icon {
    font-size: 17px;
    color: #1C1C1C;
  }

}



// tooltip css globally
.ng-tooltip {
  position: absolute; /* Keep this as absolute, so it positions relative to the host */
  width: 250px;
  font-size: 13px;
  text-align: left;
  color: #333333;
  padding: 10px;
  background: #d9d9d9;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s;
}

.ng-tooltip:after {
  position: absolute;
  content: "";
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%; /* Position below the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-color: black transparent transparent transparent;
}

.ng-tooltip-bottom:after {
  bottom: 100%; /* Position above the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-color: transparent transparent black transparent;
}

.ng-tooltip-left:after {
  left: 100%; /* Position on the right of the tooltip */
  top: 50%;
  transform: translateY(-50%);
  border-width: 5px;
  border-color: transparent transparent transparent black;
}

.ng-tooltip-right:after {
  right: 100%; /* Position on the left of the tooltip */
  top: 50%;
  transform: translateY(-50%);
  border-width: 5px;
  border-color: transparent black transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
}


.searchshow {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  cursor: pointer;

  .searchbox {
    flex-basis: 92%;
    position: relative;
  }

  .close-search {
    position: absolute;
    right: 15px;
    top: 13px;
    cursor: pointer;
  }

  .close-search ion-icon {
    font-size: 25px;
  }

  .searchbar-inline {
    padding: 12px 13px;
    border-radius: 8px;
    outline: none;
    background: #EEEEEF99;
    border: none;
    //min-height: 52px;
    width: 100%;
  }

  .search-icon {
    //flex-basis: 7%;
    //height: 52px;
    padding: 9px 13px;
    text-align: center;
    background: black;
    border-radius: 8px;
    margin-left: 10px;
    border: 2px solid #1c1c1c;
  }

  .search-outline-icon {
    font-size: 18px;
    color: white;
  }
}


/* Target devices with a max-width of 768px, often considered mobile */
@media only screen and (max-width: 768px) {
  .searchshow {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    cursor: pointer;

    .searchbox {
      //flex-basis: 90%;
      position: relative;
    }

    .close-search {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }

    .close-search ion-icon {
      font-size: 25px;
    }

    .searchbar-inline {
      padding: 12px 15px;
      border-radius: 8px;
      outline: none;
      background: #EEEEEF99;
      border: none;
      //min-height: 52px;
      width: 100%;
    }

    .search-icon {
      //flex-basis: 20%;
      //height: 52px;
      padding: 9px 13px;
      text-align: center;
      background: black;
      border-radius: 8px;
      margin-left: 10px;
    }

    .search-outline-icon {
      font-size: 18px;
      color: white;
    }
  }


}

.dropdown-itemlist{
  border: 1px solid #C5C5C7;
  border-radius: 8px;
  margin: 11px 0;
  padding: 0 20px;
  background: #FFFFFF;
  position: absolute;
  z-index: 2;
  width: 253px;

  span{
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .borderbottom {
    border-bottom: 1px solid #C5C5C7;
    cursor: pointer;
  }

  .borderbottom:last-child {
    border-bottom: none;
  }
}
// dropdown wrapper container
.dropdown-wrapper {
  width: 100%;
  position: relative;
  //padding-top: 32px;
}

// toggle container

.toggle-container {
  border: 1px solid #c5c5c7;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;

  .toggle-divider {
    height: 1px !important;
    background: #C5C5C7 !important;
    margin: 20px 0;
  }

}


// tooltip css

.tooltip-icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
}

.tooltip-icon ion-icon {
  font-size: 22px;
  color: #BDBDBD;
  cursor: pointer;
}

.ng-tooltip {
  position: absolute;
  max-width: 250px;
  font-size: 13px;
  text-align: left;
  color: #333333;
  padding: 10px;
  background: #d9d9d9;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}

.ng-tooltip:after {
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
}


// toggle container

.toggle-container {
  border: 1px solid #c5c5c7;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;

  .toggle-divider {
    height: 1px !important;
    background: #C5C5C7 !important;
    margin: 20px 0;
  }

}


.opacity-30 {
  opacity: 30%;
}
