.alert-dialog {
  .alert-message {
    font-size: 1rem!important;
  }
  .alert-success, .alert-cancel {
    font-size: 1rem!important;
    font-weight: var(--ion-font-weight-semibold);
  }
  .alert-cancel {
    color: var(--ion-color-tertiary)!important;
  }
}
