.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
//.fab-vertical-bottom {
//  //bottom: 30px;
//  //right: 20px;
//  bottom: 10%;
//  right: 17%;
//}

.home-calendar {
  --overflow: hidden;

  .page-container {
    height: calc(100% - (-5px));
  }

  full-calendar {
    height: calc(100% - 0px) !important;
  }
}
